/**
 * The category types of search results
 */
export enum SearchResultType {
  PAGES = 'PAGES',
  PROPOSALS = 'PROPOSALS',
  STOCK_CAR = 'STOCK_CAR',
  DEALER = 'DEALER',
  ASW = 'ASW'
}
