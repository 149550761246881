/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { App } from '@volkswagen-onehub/oneaudi-os-react';
import AudiHeaderDataManager from './components/AudiHeader/AudiHeaderDataManager';
import type { AudiHeaderDataManagerProps } from './interfaces/header-components.interfaces';

const FeatureApp: React.FC<AudiHeaderDataManagerProps> = ({
  audiMarketContextService,
  config,
  enablePartnerIdReplacement,
  featureAppId,
  featureServices,
  headerConfigDataUrl,
  headerStateService,
  layerManager,
  referenceServiceManager,
  ssrHeaderData,
  authService,
  localeService,
  envConfigService,
  ...inherited
}) => {
  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };

  return (
    <App config={appConfig} app={app} {...inherited}>
      <AudiHeaderDataManager
        audiMarketContextService={audiMarketContextService}
        config={config}
        enablePartnerIdReplacement={enablePartnerIdReplacement}
        featureAppId={featureAppId}
        featureServices={featureServices}
        headerConfigDataUrl={headerConfigDataUrl}
        headerStateService={headerStateService}
        layerManager={layerManager}
        referenceServiceManager={referenceServiceManager}
        ssrHeaderData={ssrHeaderData}
        authService={authService}
        localeService={localeService}
        envConfigService={envConfigService}
      />
    </App>
  );
};

export default FeatureApp;
