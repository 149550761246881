import jwtDecode from 'jwt-decode';

import type { IdkIssuerRegionMapping } from '../hooks/use-env-config/index.js';

export enum IdkRegion {
  EMEA = 'emea',
  AP = 'ap',
  NA = 'na',
}

export interface AccessToken {
  sub: string;
  iss: string;
  aud: string;
}

export function getUserId(accessToken: string): string {
  const { sub: userId }: AccessToken = jwtDecode(accessToken);
  return userId;
}

export function getLoginRegion(
  accessToken: string,
  issuerRegionMapping?: IdkIssuerRegionMapping
): IdkRegion {
  const { iss } = jwtDecode<AccessToken>(accessToken);

  if (!issuerRegionMapping) {
    throw new Error(`The issuerRegionMapping is not set`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const regionSet = Object.entries(issuerRegionMapping).find(([_region, issuer]) => issuer === iss);

  if (regionSet && regionSet.length > 0) {
    switch (regionSet[0]) {
      default:
      case 'emea':
        return IdkRegion.EMEA;
      case 'ap':
        return IdkRegion.AP;
      case 'na':
        return IdkRegion.NA;
    }
  }

  throw new Error(`The issuer '${iss}' could not be found in the mapping`);
}
