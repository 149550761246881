import type { DealerId } from '@volkswagen-onehub/gfa-dealer-context-service';
import { DEALER_ID_INTERVAL_MAX, DEALER_ID_INTERVAL_TIMEOUT, KEY_DEALER_ID } from './globals';
import { isEmpty } from './is-empty';

interface AdditionalData {
  displayName: string;
}

export type TimeRange = {
  openTime?: string;
  closeTime?: string;
};

export type OpeningHours = {
  id?: string;
  open?: boolean;
  timeRanges: TimeRange[];
};

export type Department = {
  id?: string;
  departmentName?: string;
  departmentOpeningHoursNote?: string;
  openingHours: OpeningHours[];
};

export interface Dealer {
  dealerId: DealerId;
  name: string;
  url: string;
  additionalData: AdditionalData | null;
  services: Array<string>;
  address: [string, string];
  phone: string;
  openingHours: {
    departments: Department[];
    openingHoursFormatted?: string;
    openingHoursNote?: string;
  };
}

export interface DealerData {
  dealerById: Dealer;
}

const metaValues: any = {};
export const getMetaContent = (dom: Element, metaName: string): string => {
  if (!metaValues[metaName] || dom !== metaValues[metaName].dom) {
    const metatag = dom.querySelector(`meta[name=${metaName}]`);
    metaValues[metaName] = {
      dom,
      value: metatag?.getAttribute('content') || '',
    };
  }
  return metaValues[metaName].value;
};

export const getClientId = (): string | null => {
  return getMetaContent(document.head, 'oadd-client-id') || null;
};

export const getDealerIdFromDocument = (): string | null => {
  return getMetaContent(document.head, 'oadd-dealer-id') || null;
};

export const formatID = (dealerId: string | null): DealerId | null => {
  // GraphQL needs a format like 'DEUA20991', but sometimes we get 'DEU20991' or '20991'
  // the dealer id has 5 numbers
  if (dealerId) {
    const parsedDealerId =
      dealerId.length > 5 ? dealerId.substring(dealerId.length - 5, dealerId.length) : dealerId;
    // TODO use locale service to support headless AEM
    const parsedMarket =
      dealerId.length > 5
        ? dealerId.substring(0, 3)
        : window.SETUPS?.get('nemo.locale.country.iso3');
    return {
      market: parsedMarket,
      brand: 'A',
      id: parsedDealerId,
    };
  }
  return null;
};

export const getDealerId = async (): Promise<DealerId | null> => {
  return new Promise((resolve) => {
    // 1. check if dealer id is set in the header (=> oadd website, this will set the dealer)
    let dealerId: string | null = getDealerIdFromDocument();
    if (dealerId) {
      resolve(formatID(dealerId));
    }

    // 2. otherwise check if dealer id is given as a parameter (will overwrite current dealer id)
    if (!dealerId) {
      const queryParameters = new URLSearchParams(window.location.search);
      dealerId = queryParameters.get('partner_id');
      if (dealerId) {
        resolve(formatID(dealerId));
      }
    }

    // 3. otherwise check if dealer id is given as a hash within url (will overwrite current dealer id)
    if (!dealerId) {
      const { hash } = window.location;
      const parameter = 'filter=dealer.';
      dealerId = hash.includes(parameter)
        ? hash.slice(hash.indexOf(parameter) + parameter.length)
        : null;
      if (dealerId) {
        resolve(formatID(dealerId));
      }
    }

    // 4. check if dealer id is stored in session storage with key "selected-dealer"
    if (!dealerId) {
      const session: DealerId = JSON.parse(sessionStorage.getItem(KEY_DEALER_ID) || '{}');
      dealerId = Object.values(session).join('');
      if (dealerId) {
        resolve(formatID(dealerId));
      }
    }

    // 5. check if dealer id is stored in local storage with dynamic VTP key
    if (!dealerId) {
      let timesRun = 0;
      const interval = setInterval(() => {
        timesRun += 1;
        const VTP_KEY = `VTP_${window.SETUPS?.get('stockcar.scs.market.path')}_${window.SETUPS?.get(
          'stockcar.url.startpage'
        )}`;
        const vtpStore = JSON.parse(localStorage.getItem(VTP_KEY) || '{}');
        const vtpDealer = !isEmpty(vtpStore) ? vtpStore.filterParams.filter[0] : null;
        dealerId = vtpDealer ? vtpDealer.substring(vtpDealer.length - 5, vtpDealer.length) : null;
        if (timesRun === DEALER_ID_INTERVAL_MAX || dealerId) {
          clearInterval(interval);
          resolve(formatID(dealerId));
        }
      }, DEALER_ID_INTERVAL_TIMEOUT);
    }
  });
};
