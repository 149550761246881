import React from 'react';
import {
  HeaderStyledSubNav,
  HeaderStyledSubNavButton,
  HeaderStyledSubNavList,
  HeaderStyledSubNavListContainer,
  HeaderStyledSubNavTeaserWrapper,
} from './AudiHeaderSubNavStyles';
import AudiHeaderSubNavListItem from './AudiHeaderSubNavListItem';
import { AudiHeaderSubNavProps } from '../../interfaces/header-components.interfaces';
import AudiHeaderTeaser from '../AudiHeaderTeaser/AudiHeaderTeaser';

const AudiHeaderSubNav: React.FC<AudiHeaderSubNavProps> = ({
  closeSubNavigation,
  onItemClick,
  headerNavigationItemsAmount,
  isSubNavOpen,
  subNavItem,
  useFootnoteReferenceServiceTextParserHook,
  useOneLayer,
}) => {
  const dangerousHTML = useFootnoteReferenceServiceTextParserHook(
    subNavItem.Link.Text.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      'illegalscript'
    )
  );

  return (
    <HeaderStyledSubNav
      headerNavigationItemsAmount={headerNavigationItemsAmount}
      isSubNavOpen={isSubNavOpen}
      role="tabpanel"
    >
      <div>
        <HeaderStyledSubNavButton
          headerNavigationItemsAmount={headerNavigationItemsAmount}
          onClick={closeSubNavigation}
        >
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 16.9L15.5 11.5 10 6.1" fill="none" stroke="currentColor" />
          </svg>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: dangerousHTML,
            }}
          />
        </HeaderStyledSubNavButton>
        <HeaderStyledSubNavTeaserWrapper headerNavigationItemsAmount={headerNavigationItemsAmount}>
          <HeaderStyledSubNavListContainer
            aria-label={subNavItem.Link.Text}
            headerNavigationItemsAmount={headerNavigationItemsAmount}
          >
            <HeaderStyledSubNavList headerNavigationItemsAmount={headerNavigationItemsAmount}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {subNavItem.SubNavigation.map((link) => {
                const key = `${link.Text}${link.Url}`;
                return (
                  <AudiHeaderSubNavListItem
                    isSubNavOpen={isSubNavOpen}
                    key={key}
                    listItem={link}
                    onItemClick={onItemClick}
                    useFootnoteReferenceServiceTextParserHook={
                      useFootnoteReferenceServiceTextParserHook
                    }
                    useOneLayer={useOneLayer}
                  />
                );
              })}
            </HeaderStyledSubNavList>
          </HeaderStyledSubNavListContainer>
          {subNavItem.Teaser && (
            <AudiHeaderTeaser
              isSubNavOpen={isSubNavOpen}
              teaser={subNavItem.Teaser}
              useFootnoteReferenceServiceTextParserHook={useFootnoteReferenceServiceTextParserHook}
            />
          )}
        </HeaderStyledSubNavTeaserWrapper>
      </div>
    </HeaderStyledSubNav>
  );
};
export default AudiHeaderSubNav;
