/**
 * Search events
 */
export enum SearchEventType {
  OPEN_CLOSE_FEATURE_APP = 'OPEN_CLOSE_FEATURE_APP',
  IS_MOBILE_VIEW = 'IS_MOBILE_VIEW',
  DEBUG_MODE_TOGGLED = 'DEBUG_MODE_TOGGLED',
  FOCUS = 'FOCUS',
  BLUR = 'BLUR',
  INPUT_LOADING = 'INPUT_LOADING',
  INPUT = 'INPUT',
  SEARCH_LOADING = 'SEARCH_LOADING',
  SEARCH = 'SEARCH'
}
