import {
  INavigationSearchResponse,
  SearchManagerServiceV5,
} from '@volkswagen-onehub/audi-search-manager-service/dist/interfaces';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { EnumerableFootnote } from '@oneaudi/footnote-reference-service';
import { SearchEventType } from '@volkswagen-onehub/audi-search-manager-service/dist/enums';
import { HeaderState, headerReducer } from '../../reducers/header-reducer';
import AudiHeader from './AudiHeader';
import {
  AudiHeaderDataManagerProps,
  ExternalFAVersions,
} from '../../interfaces/header-components.interfaces';
import { ExternalDependenciesType } from '../../models/ExternalFaDependencies';
import HeaderResponse from '../../interfaces/header-response.interfaces';
import { createSetActiveItemAction, IHeaderAction } from '../../actions/header-actions';
import { cleanNavigationEntries } from '../../utils/clean-navigation-entries';
import { createUseFootnoteReferenceServiceTextParserHook } from '../../services/hooks';
import { fetchHeaderConfig } from '../../services/navigation-service';
import { replacePartnerId } from '../../utils/replace-partner';
import { Dealer, getDealerId } from '../../utils/dealer-details';
import { getDealer } from '../../graphql/get-dealer';
import { KEY_DEALER_DATA, KEY_DEALER_ID } from '../../utils/globals';
import { isEmpty } from '../../utils/is-empty';

// eslint-disable-next-line max-statements
const AudiHeaderDataManager: React.FC<AudiHeaderDataManagerProps> = ({
  audiMarketContextService,
  authService,
  localeService,
  config,
  ssrHeaderData,
  headerConfigDataUrl,
  featureServices,
  headerStateService,
  featureAppId,
  referenceServiceManager,
  layerManager,
  enablePartnerIdReplacement,
  envConfigService,
}) => {
  const [footnotes, setFootnotes] = useState([]);
  const [partnerId, setPartnerId] = useState(undefined);
  const wrapperElement = React.createRef<HTMLDivElement>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [headerConfig, setHeaderConfig] = useState<HeaderResponse>(() => {
    return ssrHeaderData || fetchHeaderConfig(headerConfigDataUrl);
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [renderDealerContext, setRenderDealerContext] = useState<boolean>(false);
  const [searchServiceClientIdLocal, setSearchServiceClientIdLocal] = useState<string>();
  const [dealerSearchRedirectUrlLocal, setDealerSearchRedirectUrlLocal] = useState<string>();
  const [useOneLayer, setUseOneLayer] = useState(false);
  const [useUserMenu, setUserMenu] = useState(false);
  const [dealer, setDealer] = useState<Dealer | undefined>(undefined);
  const [versions, setVersions] = useState<ExternalFAVersions>();
  const [externalDependencies, setExternalDependencies] = useState<ExternalDependenciesType>(() => {
    return {
      loginFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-user-menu/${
        config?.usermenuFeatureAppVersion || '8'
      }/app.js`,
      miniCartFeatureAppBaseUrl: `https://featureapps.audi.com/audi-feature-app-oneshop-frontend-mini-cart/${
        config?.miniCartFeatureAppVersion || '5.1.0'
      }`,
      miniCartFeatureAppSrc: config?.miniCartFeatureAppSource || 'mini-cart.js',
      oneShopUbffUrl: config?.ubffEndPointUrl || 'https://www.audi.de/oneshop/proxy/ubff',
      searchInputFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-search-input/${
        config?.searchFeatureAppVersion || '5.0.21'
      }/audi-feature-app-search-input.umd.js`,
      searchResultsFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-search-results/${
        config?.searchFeatureAppVersion || '5.0.21'
      }/audi-feature-app-search-results.umd.js`,
    };
  });

  const headerConfigWithPartnerId = replacePartnerId(headerConfig, partnerId);

  const useFootnoteReferenceServiceTextParserHook = createUseFootnoteReferenceServiceTextParserHook(
    {
      footnotes,
      referenceServiceManager,
    }
  );

  useEffect(() => {
    (async (): Promise<void> => {
      if (typeof audiMarketContextService !== 'undefined') {
        await audiMarketContextService.initMarketContext().then(() => {
          setUseOneLayer(audiMarketContextService.hasEnvScope('ONEHEADER_ONE_LAYER'));
          setUserMenu(audiMarketContextService.hasEnvScope('ENABLE_USER_MENU'));
          setRenderDealerContext(audiMarketContextService.hasEnvScope('RENDER_DEALER_CONTEXT'));
          if (audiMarketContextService.hasEnvScope('ENABLE_DEALER_CONTEXT')) {
            const sessionActive = !isEmpty(
              JSON.parse(sessionStorage.getItem(KEY_DEALER_ID) || '{}')
            );
            if (!sessionActive) {
              sessionStorage.removeItem(KEY_DEALER_DATA);
            }
            const storage = sessionStorage.getItem(KEY_DEALER_DATA);
            const dealerDetailsStored: Dealer = storage !== null ? JSON.parse(storage) : undefined;
            getDealerId().then((currentDealerId) => {
              setDealer(() => {
                return dealerDetailsStored || undefined;
              });
              if (
                !!currentDealerId?.id &&
                (!dealerDetailsStored || dealerDetailsStored?.dealerId.id !== currentDealerId.id)
              ) {
                sessionStorage.setItem(KEY_DEALER_ID, JSON.stringify(currentDealerId));
                getDealer(
                  process.env.GRAPHQL_DEALER_ENDPOINT,
                  Object.values(currentDealerId).join('')
                )
                  .then((dealerDetails) => {
                    if (dealerDetails) {
                      const { dealerById } = dealerDetails;
                      setDealer(dealerById);
                      sessionStorage.setItem(KEY_DEALER_DATA, JSON.stringify(dealerById));
                    }
                  })
                  .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.error('[nemo-header|dealer-data]', err);
                  });
              }
            });
          }
          setVersions(() => {
            return {
              usermenuFeatureAppVersion: audiMarketContextService.getContextItem(
                'UsermenuFeatureAppVersion'
              ) as string,
              miniCartFeatureAppVersion: audiMarketContextService.getContextItem(
                'MiniCartFeatureAppVersion'
              ) as string,
              miniCartFeatureAppSource: audiMarketContextService.getContextItem(
                'MiniCartFeatureAppSource'
              ) as string,
              ubffEndPointUrl: audiMarketContextService.getContextItem('UbffEndPointUrl') as string,
              searchFeatureAppVersion: audiMarketContextService.getContextItem(
                'SearchFeatureAppVersion'
              ) as string,
            };
          });
        });
      }
    })();
  }, [audiMarketContextService]);

  // retrieve market context service data if available and if needed
  useEffect(() => {
    setExternalDependencies((inital) => {
      return {
        loginFeatureAppUrl:
          !config?.usermenuFeatureAppVersion && versions?.usermenuFeatureAppVersion
            ? `https://featureapps.audi.com/audi-feature-app-user-menu/${versions.usermenuFeatureAppVersion}/app.js`
            : inital.loginFeatureAppUrl,
        miniCartFeatureAppBaseUrl:
          !config?.miniCartFeatureAppVersion && versions?.miniCartFeatureAppVersion
            ? `https://featureapps.audi.com/audi-feature-app-oneshop-frontend-mini-cart/${versions.miniCartFeatureAppVersion}`
            : inital.miniCartFeatureAppBaseUrl,
        miniCartFeatureAppSrc:
          !config?.miniCartFeatureAppSource && versions?.miniCartFeatureAppSource
            ? versions.miniCartFeatureAppSource
            : inital.miniCartFeatureAppSrc,
        oneShopUbffUrl:
          !config?.ubffEndPointUrl && versions?.ubffEndPointUrl
            ? versions.ubffEndPointUrl
            : inital.oneShopUbffUrl,
        searchInputFeatureAppUrl:
          !config?.searchFeatureAppVersion && versions?.searchFeatureAppVersion
            ? `https://featureapps.audi.com/audi-feature-app-search-input/${versions.searchFeatureAppVersion}/audi-feature-app-search-input.umd.js`
            : inital.searchInputFeatureAppUrl,
        searchResultsFeatureAppUrl:
          !config?.searchFeatureAppVersion && versions?.searchFeatureAppVersion
            ? `https://featureapps.audi.com/audi-feature-app-search-results/${versions.searchFeatureAppVersion}/audi-feature-app-search-results.umd.js`
            : inital.searchResultsFeatureAppUrl,
      };
    });
  }, [
    config?.usermenuFeatureAppVersion,
    config?.miniCartFeatureAppSource,
    config?.miniCartFeatureAppVersion,
    config?.searchFeatureAppVersion,
    config?.ubffEndPointUrl,
    config?.lamdaApiEndpoint,
  ]);

  useEffect(() => {
    if (window && window.location.href.indexOf('debug=true') !== -1) {
      headerStateService.setDebugMode(true);
    }
    return (): void => {
      if (window) {
        if (window.location.href.indexOf('debug=true') !== -1) {
          headerStateService.setDebugMode(true);
        } else {
          headerStateService.setDebugMode(false);
        }
      }
    };
  }, []);

  useEffect(() => {
    if (referenceServiceManager) {
      referenceServiceManager.registerCallback((_footnotes: EnumerableFootnote[]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return setFootnotes(_footnotes);
      });
    }
    return (): void => {
      if (referenceServiceManager) {
        referenceServiceManager.removeFootnoteReferences();
      }
    };
  }, []);

  useEffect(() => {
    // always load clientside again, to be sure, not to work with a cached version from SSR
    fetchHeaderConfig(headerConfigDataUrl)
      .then((fetchedHeaderConfigData) => {
        const cleanedResponse = cleanNavigationEntries(fetchedHeaderConfigData);
        setHeaderConfig(cleanedResponse);
        setIsLoading(false);
      })
      // eslint-disable-next-line no-console
      .catch((headerConfigError) => console.error(headerConfigError));
  }, [headerConfigDataUrl, ssrHeaderData]);

  const [headerState, headerDispatch] = useReducer<React.Reducer<HeaderState, IHeaderAction>>(
    headerReducer,
    {
      activeItem: {
        anchor: null,
        index: -1,
        showSearch: false,
        showDealer: false,
        showLoginFlyout: false,
      },
      requestLoadSearchApp: false,
    }
  );

  useEffect(() => {
    if (window && headerState && headerState.requestLoadSearchApp) {
      const searchManagerService: SearchManagerServiceV5 = featureServices[
        'search-manager-service'
      ] as SearchManagerServiceV5;
      if (!searchManagerService) {
        return;
      }

      const {
        setVTPAndDealerSearchConfig,
        setDealerSearchRedirectUrl,
        setClientIdParam,
        setNavigationSearchData,
      } = searchManagerService;

      if (
        headerConfigWithPartnerId?.Search?.OneHeaderSearchClientId &&
        setClientIdParam &&
        searchServiceClientIdLocal !== headerConfigWithPartnerId.Search.OneHeaderSearchClientId
      ) {
        const searchProposalsUrl = window.SETUPS?.get('nemo.url.searchproposals') || '';

        if (searchProposalsUrl !== '') {
          searchManagerService.setSuggestedAndProposalSearchUrl(searchProposalsUrl);
        }

        searchManagerService.events.on(
          SearchEventType.DEBUG_MODE_TOGGLED,
          (debugState: boolean) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (typeof window !== 'undefined' && typeof window['core-global'] !== 'undefined') {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window['core-global'].state.triggerModeChange('debug', debugState);
            }
            headerStateService.setDebugMode(debugState);
          }
        );

        // avoid setting the client id parameter multple times with the same value
        setSearchServiceClientIdLocal(headerConfigWithPartnerId.Search.OneHeaderSearchClientId);

        // communicate client id to search manager service
        setClientIdParam(headerConfigWithPartnerId.Search.OneHeaderSearchClientId);
      }

      if (headerConfigWithPartnerId?.MainNavigation && setNavigationSearchData) {
        setNavigationSearchData({
          MainNavigation: headerConfigWithPartnerId.MainNavigation,
        } as INavigationSearchResponse);
      }

      const vtpConfig = {
        dealerSearch: !!headerConfigWithPartnerId?.Search?.ShowDealerSearchLink,
        newStockCarSearch: !!headerConfigWithPartnerId?.Search?.ShowNewCarsLink,
        usedAndPreOwnedStockCarSearch: !!headerConfigWithPartnerId?.Search?.ShowUsedCarsLink,
      };

      setVTPAndDealerSearchConfig(vtpConfig);

      if (
        headerConfigWithPartnerId?.Search?.DealerSearchLink &&
        setDealerSearchRedirectUrl &&
        dealerSearchRedirectUrlLocal !== headerConfigWithPartnerId.Search.DealerSearchLink
      ) {
        // avoid setting property multiple times with the same value
        setDealerSearchRedirectUrlLocal(headerConfigWithPartnerId.Search.DealerSearchLink);

        // set the direct url for dealer to Search Service Manager Service
        setDealerSearchRedirectUrl(headerConfigWithPartnerId.Search.DealerSearchLink);
      }
    }
  }, [
    headerConfigWithPartnerId,
    setSearchServiceClientIdLocal,
    setDealerSearchRedirectUrlLocal,
    dealerSearchRedirectUrlLocal,
    featureServices,
    headerStateService,
    searchServiceClientIdLocal,
    headerState,
  ]);

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !enablePartnerIdReplacement ||
      !(window.microkernel && window.microkernel.stateRegistry)
    ) {
      return (): void => {
        // Do nothing
      };
    }

    const partnerCallback = (state: React.SetStateAction<undefined>): void => {
      setPartnerId(state);
    };

    window.microkernel.stateRegistry.subscribeToStore('dbadDealerStore', partnerCallback);

    return (): void => {
      window.microkernel.stateRegistry.unsubscribeFromStore('dbadDealerStore', partnerCallback);
    };
  }, [enablePartnerIdReplacement, setPartnerId]);

  const removeDealer = useCallback((): void => {
    setDealer(undefined);
    sessionStorage.removeItem(KEY_DEALER_DATA);
    sessionStorage.removeItem(KEY_DEALER_ID);
    headerDispatch(
      createSetActiveItemAction({
        anchor: null,
        headerStateService,
        index: -1,
        showSearch: false,
        showDealer: false,
        showLoginFlyout: false,
      })
    );
  }, [setDealer]);

  return (
    <AudiHeader
      authService={authService}
      localeService={localeService}
      audiMarketContextService={audiMarketContextService}
      externalFeatureApps={externalDependencies}
      featureAppId={featureAppId}
      headerConfig={headerConfigWithPartnerId}
      headerDispatch={headerDispatch}
      headerState={headerState}
      headerStateService={headerStateService}
      isLoading={isLoading}
      layerManager={layerManager}
      useFootnoteReferenceServiceTextParserHook={useFootnoteReferenceServiceTextParserHook}
      useOneLayer={useOneLayer}
      wrapperElement={wrapperElement}
      dealer={dealer}
      removeDealer={removeDealer}
      renderDealerContext={renderDealerContext}
      envConfigService={envConfigService}
      useUserMenu={useUserMenu}
    />
  );
};

export default AudiHeaderDataManager;
