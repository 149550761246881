import React, { useMemo } from 'react';
import { LayoutItem, Icon, Text, Badge } from '@audi/audi-ui-react-v2';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { IconProps } from '@audi/audi-ui-react';
import { Department } from '../../utils/dealer-details';
import {
  getWeekDay,
  timeFormatted,
  isServiceOpen,
  getNextOpenDay,
  isBeforeOpening,
} from '../../utils/time-utils';
import { upperCase } from '../../utils/string-utils';
import { DealerDetailStyled, OpenHoursStyled } from './DealerDetailStyles';
import { messages } from '../../i18n/messages';

type DepartmentOpeningHoursProps = {
  department: Department;
  icon?: IconProps['name'];
};
export const DepartmentOpeningHours: React.FC<DepartmentOpeningHoursProps> = ({
  icon = 'time',
  department,
}) => {
  const { openingHours, departmentName } = department;
  const today = useMemo(() => getWeekDay(), []);
  const openingHoursToday = openingHours.find((weekday) => weekday.id === today);
  const openTime = timeFormatted(openingHoursToday?.timeRanges[0]?.openTime);
  const closeTime = timeFormatted(openingHoursToday?.timeRanges[0]?.closeTime);
  const isOpen = isServiceOpen(openTime, closeTime);
  const opensSoon = isBeforeOpening(openTime);
  const next =
    !isOpen && !opensSoon
      ? getNextOpenDay(openingHours, openingHoursToday || openingHours[0])
      : openingHours[0];
  const openFragment = useI18n(messages.open_until);
  const closedFragment = useI18n(messages.closed_fragment);
  const todayFragment = useI18n(messages.today);
  const timeFormat = useI18n(messages.time_format);

  return (
    <DealerDetailStyled>
      <LayoutItem align="center" spaceInlineEnd="s">
        <Icon name={icon} size="small" variant="primary" />
      </LayoutItem>
      <LayoutItem>
        <Text>{departmentName}</Text>
        <OpenHoursStyled>
          <Badge
            variant={isOpen ? 'positive' : 'informative'}
            spaceInlineEnd="s"
            spaceStackStart="xxs"
          />
          {isOpen ? (
            <Text>
              {openFragment} {closeTime} {timeFormat}
            </Text>
          ) : (
            <>
              {!opensSoon ? (
                <Text>
                  {closedFragment} {upperCase(next?.id)}
                  {timeFormatted(next?.timeRanges[0].openTime)} {timeFormat}
                </Text>
              ) : (
                <Text>
                  {closedFragment} {todayFragment} {openTime} {timeFormat}
                </Text>
              )}
            </>
          )}
        </OpenHoursStyled>
      </LayoutItem>
    </DealerDetailStyled>
  );
};
