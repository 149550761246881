// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { responsiveStyles } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import {
  HeaderStyledShaderProps,
  HeaderStyledWrapperProps,
} from '../../interfaces/header-styled-components.interfaces';
import { timeL, timeXXL, timeS } from './AudiHeaderStyleUtils';
import { createBreakpointStyles } from '../../services';

const headerBreakpointStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      'flex-wrap': {
        l: 'nowrap',
      },
      'max-width': {
        l: '100%',
      },
      overflow: {
        l: 'initial',
      },
      'z-index': {
        l: '1',
      },
    },
  },
  {
    maxNavItems: 5,
    style: {
      'flex-wrap': {
        xl: 'nowrap',
      },
      'max-width': {
        xl: '100%',
      },
      overflow: {
        xl: 'initial',
      },
      'z-index': {
        xl: '1',
      },
    },
  },
  {
    maxNavItems: 8,
    style: {
      'flex-wrap': {
        xl: 'nowrap',
      },
      'max-width': {
        xl: '100%',
      },
      overflow: {
        xl: 'initial',
      },
      'z-index': {
        xl: '1',
      },
    },
  },
  {
    maxNavItems: -1,
    style: null,
  },
];

export const HeaderStyled = styled.div<{
  disableTransition?: boolean;
}>`
  --one-header-color-black: var(${({ theme }): string => theme.colors.base.brand.black});
  --one-header-color-white: var(${({ theme }): string => theme.colors.base.brand.white});
  --one-header-color-grey: var(${({ theme }): string => theme.colors.base.grey[5]});
  --one-header-color-red-rhombus: var(
    ${({ theme }): string => theme.colors.base.brand.progressiveRed}
  );
  --one-header-color-transparent: 'transparent';
  --one-header-height: 72px;
  --audi-rings-logo-height: 24px;
  --audi-rings-logo-width: 69px;
  --one-header-space-xs: var(${({ theme }): string => theme.responsive.spacing.xs});
  --one-header-space-s: var(${({ theme }): string => theme.responsive.spacing.s});
  --one-header-space-m: var(${({ theme }): string => theme.responsive.spacing.m});
  --one-header-space-l: var(${({ theme }): string => theme.responsive.spacing.l});
  --one-header-space-xl: var(${({ theme }): string => theme.responsive.spacing.xl});
  --one-header-space-xxl: var(${({ theme }): string => theme.responsive.spacing.xxl});
  --one-header-space-xxxl: var(${({ theme }): string => theme.responsive.spacing.xxxl});
  --one-header-side-spacing: 16px;
  /* stylelint-disable */
  --one-header-flyout-left-spacing: calc(
    var(--one-header-side-spacing) + var(--audi-rings-logo-width) + var(--one-header-space-xl)
  );
  /* stylelint-enable */
  box-sizing: border-box;
  display: block;
  font-size: 10px;
  ${({ theme }): string =>
    responsiveStyles(
      {
        '--one-header-side-spacing': {
          l: '60px',
          m: '40px',
          s: '28px',
          xl: '96px',
          xxl: 'calc((100% - 1728px) / 2)',
        },
      },
      theme.breakpoints
    )}
  position: relative;
  touch-action: manipulation;
  width: 100%;
  z-index: 100;

  & *,
  & *::after,
  & *::before {
    box-sizing: border-box;
    ${({ disableTransition }): string => disableTransition && 'transition: none !important;'}
  }
`;

export const HeaderStyledWrapper = styled.div<HeaderStyledWrapperProps>`
  background-color: var(--one-header-color-white);
  display: flex;
  height: var(--one-header-height);
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(headerNavigationItemsAmount, headerBreakpointStyles()),
      theme.breakpoints
    )}
  padding: 0 var(--one-header-side-spacing);
  position: relative;
  z-index: 100;
`;

export const HeaderStyledShader = styled.div<HeaderStyledShaderProps>`
  transition: background-color ${timeS} var(${({ theme }): string => theme.easing});
  background-color: var(
    ${({ showShader }): string =>
      showShader ? '--one-header-color-black' : '--one-header-color-transparent'}
  );
  bottom: 0;
  content: \'\';
  display: block;
  left: 0;
  opacity: 0.65;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
`;

export const HeaderStyledUserActionWrapper = styled.div<{
  isLoading: boolean;
}>`
  background-color: var(--one-header-color-white);
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  opacity: ${({ isLoading }): string => (isLoading ? '0' : '1')};
  transition: opacity var(${({ theme }): string => theme.easing}) ${timeL} ${timeXXL};
  width: ${({ isLoading }): string => (isLoading ? '0px' : '33%')};
`;
