import React from 'react';
import { LayoutItem, Icon, Text } from '@audi/audi-ui-react-v2';
import type { IconProps } from '@audi/audi-ui-react-v2';
import { DealerDetailStyled, DealerTelStyled } from './DealerDetailStyles';
import { Dealer } from '../../utils/dealer-details';
import { PoiIcon } from '../Icons/PoiIcon';

export interface DealerDetailProps {
  icon?: IconProps['name'] | 'poi-icon';
  detail?: Dealer['address'] | string;
}

export const DealerDetail: React.FC<DealerDetailProps> = ({ icon, detail }) => {
  const detailsArray = typeof detail === 'string' ? [detail] : detail;

  const onClickCall = (telNumber: string, e?: React.KeyboardEvent) => {
    if (typeof window === undefined || e?.key === 'Tab') return;
    window.location.href = `tel: ${telNumber}`;
  };

  return (
    <DealerDetailStyled>
      {icon && (
        <LayoutItem align="center" spaceInlineEnd="m">
          {icon === 'poi-icon' ? <PoiIcon /> : <Icon name={icon} size="small" variant="primary" />}
        </LayoutItem>
      )}
      <LayoutItem>
        {detailsArray &&
          detailsArray.map((detailText, index) => {
            return icon === 'voice-call-1' ? (
              <DealerTelStyled
                role="button"
                key={detailText}
                tabIndex={index}
                onKeyDown={(e) => onClickCall(detailText, e)}
                onClick={() => onClickCall(detailText)}
              >
                <Text aria-label={detailText}>{detailText}</Text>
              </DealerTelStyled>
            ) : (
              <Text aria-label={detailText} key={detailText}>
                {detailText}
              </Text>
            );
          })}
      </LayoutItem>
    </DealerDetailStyled>
  );
};
