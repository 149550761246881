import React from 'react';
import {
  LayoutItem,
  Icon,
  Text,
  Button,
  PopoverTrigger,
  Popover,
  PopoverBody,
} from '@audi/audi-ui-react-v2';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import {
  AdditionalDealerData,
  AdditionalDealerDataActionsBottom,
  AdditionalDealerDataContent,
  AdditionalDealerDataDisconnectButton,
  AdditionalDealerDataInfoButton,
} from './AudiHeaderAdditionalDealerDataStyles';
import { DealerDetail } from './DealerDetail';
import { DepartmentOpeningHours } from './DepartmentOpeningHours';
import { AudiHeaderDealerDataProps } from '../../interfaces/header-components.interfaces';
import { upperCase } from '../../utils/string-utils';
import { messages } from '../../i18n/messages';
import { ViewportProvider } from '../../context/Viewport';

const AudiHeaderAdditionalDealerData: React.FC<AudiHeaderDealerDataProps> = ({
  dealer,
  open,
  isMobileView,
  removeDealer,
}) => {
  const backToDealerText = useI18n(messages.back_to_dealer);
  const removeDealerButtonText = useI18n(messages.remove_dealer);
  const removeDealerHelpText = useI18n(messages.remove_dealer_help);
  return (
    <ViewportProvider>
      <AdditionalDealerData open={open} isMobileView={isMobileView}>
        <AdditionalDealerDataContent isMobileView={isMobileView}>
          {!isMobileView && (
            <LayoutItem spaceStackEnd="xl">
              <Text as="h2" variant="order2">
                {dealer.additionalData?.displayName || dealer.name || 'Name not found'}
              </Text>
              <Text as="p" variant="order4">
                {dealer.services.map((service, index) => {
                  const separator = index !== dealer.services.length - 1 ? ', ' : '';
                  return `${upperCase(service)}${separator}`;
                })}
              </Text>
            </LayoutItem>
          )}
          <Button
            variant="secondary"
            href={dealer.url}
            spaceStackEnd={isMobileView ? 'l' : 'xl'}
            aria-label="link to dealer page"
            disabled={!open}
          >
            <Text>{backToDealerText}</Text>
          </Button>
          {dealer.address && <DealerDetail icon="poi-icon" detail={dealer.address} />}
          {dealer.phone && <DealerDetail icon="voice-call-1" detail={dealer.phone} />}
          {dealer.openingHours &&
            dealer.openingHours.departments.map((department) => (
              <DepartmentOpeningHours key={department.departmentName} department={department} />
            ))}
          <AdditionalDealerDataActionsBottom isMobileView={isMobileView}>
            <AdditionalDealerDataDisconnectButton
              onClick={removeDealer}
              aria-label="disconnect from curren dealer"
              tabIndex={open ? 0 : -1}
            >
              <LayoutItem align="center" spaceInlineEnd="xs">
                <Icon name="system-cancel" size="small" variant="primary" />
              </LayoutItem>
              <LayoutItem align="center" spaceInlineEnd="xs">
                <Text>{removeDealerButtonText}</Text>
              </LayoutItem>
            </AdditionalDealerDataDisconnectButton>
            <AdditionalDealerDataInfoButton>
              <PopoverTrigger tip placement="top">
                {(triggerProps) => (
                  <>
                    <Button
                      aria-label="triggers popover for remove dealer"
                      variant="icon-tertiary"
                      icon="system-info"
                      disabled={!open}
                      {...triggerProps}
                    />
                    <Popover aria-label="Popover content for remove dealer">
                      <PopoverBody>
                        <Text>{removeDealerHelpText}</Text>
                      </PopoverBody>
                    </Popover>
                  </>
                )}
              </PopoverTrigger>
            </AdditionalDealerDataInfoButton>
          </AdditionalDealerDataActionsBottom>
        </AdditionalDealerDataContent>
      </AdditionalDealerData>
    </ViewportProvider>
  );
};

export default AudiHeaderAdditionalDealerData;
