import styled, { css } from 'styled-components';
import { responsiveStyles } from '@audi/audi-ui-react-v2';
import {
  AdditionalDealerDataContentProps,
  AdditionalDealerDataProps,
} from '../../interfaces/header-styled-components.interfaces';
import { customEaseOut } from '../../utils/globals';

export const AdditionalDealerData = styled.div<AdditionalDealerDataProps>`
  transition: max-height 350ms ${customEaseOut};
  color: var(--one-header-color-black);
  background: var(
    ${({ isMobileView }) => (isMobileView ? '--one-header-color-grey' : '--one-header-color-white')}
  );
  top: ${({ isMobileView }) => (isMobileView ? 0 : 'var(--one-header-height)')};
  position: ${({ isMobileView }) => (isMobileView ? 'relative' : 'absolute')};
  overflow-y: scroll;
  max-height: ${({ open }) => (open ? '100vh' : 0)};
  scrollbar-width: none; /*  hide scrollbar for Firefox */

  ${({ isMobileView }) =>
    !isMobileView &&
    css`
      height: calc(var(--viewport-height) - var(--one-header-height));
    `}
  ${({ open, isMobileView, theme }) =>
    open &&
    isMobileView &&
    css`
      border-bottom: 1px solid var(${theme.colors.base.grey[20]});
    `}
  ${({ theme }): string =>
    responsiveStyles(
      {
        width: {
          xl: '447px',
        },
        right: {
          xl: '0',
        },
      },
      theme.breakpoints
    )};
`;

export const AdditionalDealerDataContent = styled.div<AdditionalDealerDataContentProps>`
  padding: calc(var(--one-header-space-xxl) - var(--one-header-space-l) / 2)
    var(
      ${({ theme, isMobileView }): string => {
        return isMobileView ? '--one-header-side-spacing' : theme.responsive.spacing.xl;
      }}
    )
    0;
  display: flex;
  flex-direction: column;
`;

export const AdditionalDealerDataActionsBottom = styled.div<AdditionalDealerDataContentProps>`
  display: flex;
  border-top: 1px solid var(${({ theme }): string => theme.colors.base.grey[20]});
  padding: var(--one-header-space-l) 0 calc(var(--one-header-space-l) + 5px);
  margin-top: var(--one-header-space-m);
`;

export const AdditionalDealerDataDisconnectButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  padding: 0;
  &:focus-visible {
    outline: 2px solid;
    border-radius: 3px;
  }
`;
export const AdditionalDealerDataInfoButton = styled.div`
  background: transparent;
  align-items: flex-start;
`;
