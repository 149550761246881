/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';

import { getLoginRegion, getUserId } from '../../utils/idk';

import type { IdkConfig } from '../use-env-config';

export interface PersonalData {
  readonly salutation?: string;
  readonly academicTitle?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly nickname?: string;
  readonly profilePicture?: {
    id: string;
    etag: string;
  };
}

export type UserInfo = {
  personalData?: PersonalData;
};

const getServiceUrl = (baseUrl: string, userId: string, folder: string): string => {
  return `${baseUrl}/customers/${userId}/${folder}`;
};

export function useUserInfo(idk?: IdkConfig, accessToken?: string): UserInfo {
  const [personalData, setPersonalData] = useState<PersonalData>();

  // @ts-ignore
  const [useInfoUrl, setUseInfoUrl] = useState<string>();
  useEffect(() => {
    const getPersonalData = async () => {
      if (idk && accessToken) {
        const loggedInRegion = getLoginRegion(accessToken, idk.issuerRegionMapping);
        const customerProfileServiceConfig = idk.customerProfileService[loggedInRegion];
        const userId = getUserId(accessToken);

        try {
          const url = getServiceUrl(
            customerProfileServiceConfig?.baseUrl || '',
            userId,
            'personalData'
          );
          setUseInfoUrl(url);
          const response = await fetch(url, {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          });
          const version = response.headers.get('etag');

          if (response.ok) {
            if (version) {
              setPersonalData({ _version: version, ...(await response.json()) });
            }
          } else {
            throw Error(
              `Error fetching personal data: ${response.statusText} (${response.status})`
            );
          }
        } catch {
          throw Error(`Error fetching personal data`);
        }
      }
    };
    getPersonalData();
  }, [accessToken, idk]);

  return {
    personalData,
  };
}
