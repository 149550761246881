import React from 'react';
import { responsiveStyles } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { AudiHeaderLogoProps } from '../../interfaces/header-components.interfaces';
import { HeaderStyledLogoProps } from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services/index';
import { timeM } from '../AudiHeader/AudiHeaderStyleUtils';

const headerStyledLogoStyles = (): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      'flex-grow': { l: '0' },
      'margin-left': {
        l: '0',
      },
      'margin-right': {
        l: 'var(--one-header-space-xl)',
      },
      opacity: { l: '1' },
      'pointer-events': { l: 'auto' },
      transition: { l: 'none' },
    },
  },
  {
    maxNavItems: 5,
    style: {
      'flex-grow': { xl: '0' },
      'margin-left': {
        xl: '0',
      },
      'margin-right': {
        xl: 'var(--one-header-space-xl)',
      },
      opacity: { xl: '1' },
      'pointer-events': { xl: 'auto' },
      transition: { xl: 'none' },
    },
  },
  {
    maxNavItems: 8,
    style: {
      'flex-grow': { xxl: '0' },
      'margin-left': {
        xxl: '0',
      },
      'margin-right': {
        xxl: 'var(--one-header-space-xl)',
      },
      opacity: { xxl: '1' },
      'pointer-events': { xxl: 'auto' },
      transition: { xxl: 'none' },
    },
  },
  {
    maxNavItems: -1, // > 8 navItems
    style: null,
  },
];

const HeaderStyledLogo = styled.div<HeaderStyledLogoProps>`
  background-color: var(--one-header-color-white);
  line-height: 0;
  margin-bottom: var(--one-header-space-l);
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--one-header-space-l);
  ${({ headerNavigationItemsAmount, theme }): string =>
    responsiveStyles(
      createBreakpointStyles(headerNavigationItemsAmount, headerStyledLogoStyles()),
      theme.breakpoints
    )}
  opacity: ${({ isOpen }): string => (isOpen ? '0' : '1')};
  pointer-events: ${({ isOpen }): string => (isOpen ? 'none' : 'auto')};
  text-align: center;
  transition: opacity ${timeM} ${({ theme }): string => theme.easing};

  & a {
    display: inline-block;
  }

  & a svg {
    height: var(--audi-rings-logo-height);
    width: var(--audi-rings-logo-width);
  }

  & a strong {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }
`;

const AudiHeaderLogo: React.FC<AudiHeaderLogoProps> = (props) => {
  const { headerNavigationItemsAmount, isLoading, isOpen, logoLink } = props;

  if (isLoading) {
    return (
      <HeaderStyledLogo headerNavigationItemsAmount={headerNavigationItemsAmount} isOpen={isOpen}>
        <AudiHeaderLogoImage />
      </HeaderStyledLogo>
    );
  }

  return (
    <HeaderStyledLogo headerNavigationItemsAmount={headerNavigationItemsAmount} isOpen={isOpen}>
      <a aria-label={logoLink?.Text} href={logoLink?.Url} title={logoLink?.Text}>
        <AudiHeaderLogoImage />
        <strong>{logoLink?.Text}</strong>
      </a>
    </HeaderStyledLogo>
  );
};

const AudiHeaderLogoImage: React.FC = () => {
  return (
    <svg height="24" width="69" xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable max-len */}
      <path
        d="M56.927 0a11.798 11.798 0 00-7.495 2.671A11.906 11.906 0 0041.9 0a11.719 11.719 0 00-7.494 2.671C32.347 1.006 29.745 0 26.91 0a11.798 11.798 0 00-7.494 2.671C17.358 1.006 14.756 0 11.92 0 5.32 0 0 5.303 0 11.884c0 6.58 5.32 11.884 11.921 11.884 2.835 0 5.475-1.007 7.495-2.671 2.058 1.664 4.66 2.67 7.494 2.67 2.835 0 5.475-1.006 7.495-2.67a11.906 11.906 0 007.533 2.67c2.874 0 5.475-1.006 7.533-2.67 2.058 1.664 4.66 2.67 7.495 2.67 6.601 0 11.921-5.302 11.921-11.883C68.848 5.342 63.528 0 56.927 0zm-7.495 17.226a9.126 9.126 0 01-1.708-5.342c0-1.974.621-3.832 1.708-5.342a9.126 9.126 0 011.709 5.342c0 2.013-.66 3.832-1.709 5.342zm-15.027 0a9.126 9.126 0 01-1.709-5.342c0-1.974.621-3.832 1.709-5.342a9.126 9.126 0 011.708 5.342c0 2.013-.66 3.832-1.708 5.342zm-15.028 0a9.126 9.126 0 01-1.709-5.342c0-1.974.622-3.832 1.709-5.342a9.126 9.126 0 011.708 5.342c0 2.013-.66 3.832-1.708 5.342zM2.602 11.884c0-5.071 4.116-9.213 9.242-9.213a9.22 9.22 0 015.63 1.897c-1.592 2.013-2.524 4.529-2.524 7.316 0 2.748.932 5.303 2.524 7.316-1.553 1.2-3.495 1.897-5.63 1.897-5.087 0-9.242-4.103-9.242-9.213zM21.24 19.2c1.592-2.013 2.524-4.529 2.524-7.316 0-2.749-.932-5.303-2.524-7.316 1.553-1.2 3.495-1.897 5.63-1.897 2.136 0 4.078.697 5.63 1.897-1.591 2.013-2.523 4.529-2.523 7.316 0 2.748.932 5.303 2.524 7.316-1.553 1.2-3.495 1.897-5.63 1.897-2.136 0-4.078-.697-5.631-1.897zm15.028 0c1.592-2.013 2.524-4.529 2.524-7.316 0-2.749-.932-5.303-2.524-7.316 1.553-1.2 3.494-1.897 5.63-1.897a9.22 9.22 0 015.63 1.897c-1.591 2.013-2.523 4.529-2.523 7.316 0 2.748.932 5.303 2.524 7.316-1.554 1.2-3.495 1.897-5.63 1.897a9.22 9.22 0 01-5.631-1.897zm20.658 1.897a9.22 9.22 0 01-5.63-1.897c1.591-2.013 2.523-4.529 2.523-7.316 0-2.749-.932-5.303-2.524-7.316 1.554-1.2 3.495-1.897 5.63-1.897 5.088 0 9.243 4.103 9.243 9.213 0 5.11-4.155 9.213-9.242 9.213z"
        fill="#000"
        fillRule="evenodd"
      />
      {/* eslint-enable max-len */}
    </svg>
  );
};

export default AudiHeaderLogo;
