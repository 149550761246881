// eslint-disable-next-line import/no-unresolved
import { GraphQLClient, gql } from 'graphql-request';
import { DealerData, getClientId } from '../utils/dealer-details';

const QUERY_BY_DEALER_ID = gql`
  query Dealer($dealerId: String!) {
    dealerById(dealerId: $dealerId) {
      name
      url
      additionalData {
        displayName
      }
      dealerId
      services
      address
      phone
      openingHours {
        openingHoursFormatted
        openingHoursNote
        departments {
          id
          departmentName
          departmentOpeningHoursNote
          openingHours {
            id
            open
            timeRanges {
              openTime
              closeTime
            }
          }
        }
      }
    }
  }
`;

export async function getDealer(
  endpoint: string | undefined,
  dealerId: string | undefined
): Promise<DealerData | undefined> {
  const clientId = getClientId();
  let client;

  if (__DEBUG__ && !dealerId) {
    // eslint-disable-next-line no-console
    console.warn('[nemo-header|dealer-data] no Dealer ID found');
  }

  if (endpoint !== undefined && clientId) {
    client = new GraphQLClient(endpoint, {
      headers: {
        clientId,
      },
    });
  } else if (__DEBUG__) {
    // eslint-disable-next-line no-console
    console.warn('[nemo-header|dealer-data] no graphql endpoint or clientId provided');
  }

  return dealerId && client
    ? client.request(
        QUERY_BY_DEALER_ID,
        {
          dealerId,
        },
        { accept: 'application/json' }
      )
    : undefined;
}
