/*
  The index.tsx file is explicitly taken from the below repo.
  https://github.com/oneaudi/myaudi/tree/develop/shared/myaudi-components/patterns/rich-text
  The import from myaudi could not be possible due to authorisation issues.
  During the serving of the fa there appears a problem with the authentication to npm. 
  Hence the files core-renderer and index.tsx have been explicitly taken from the repo and added in the oneHeader Fa.
  We will replace this preferably with a component from Audi Ui React in the near future.
*/
import * as React from 'react';

import { coreRenderer } from './core-renderer';

const DOMParser =
  'DOMParser' in global ? (global as any).DOMParser : require('@xmldom/xmldom').DOMParser;

const NODE_TYPE_ELEMENT_NODE = 1;

export interface Attributes {
  [key: string]: string;
}

export type nodeRendererType = (
  node: Node,
  attributes: Attributes,
  nodeContent: JSX.Element[],
  richTextProps?: Omit<RichTextProps, 'nodeRenderer'>
) => JSX.Element | string | null;

export interface RichTextProps {
  moduleName?: string;
  children: string;
  nodeRenderer?: nodeRendererType;
}

export default class RichText extends React.PureComponent<RichTextProps> {
  static parse(content: string): Element[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(`<body>${content}</body>`, 'text/html');
    const body = doc.body || doc.lastChild;

    return body === null ? [] : Array.prototype.slice.call(body.childNodes);
  }

  /**
   * Renders a node from the stack
   *
   * @param node Node
   */

  /**
   * Transforms NamedNodeMap into a plain object
   *
   * @param element Element
   */
  static getAttributes(element: Element): Attributes {
    const result: Attributes = {};

    Array.from(element.attributes).forEach(({ name, value }) => {
      result[name] = value;
    });

    return result;
  }

  protected renderNode(node: Node): JSX.Element | number | string | null {
    const { nodeRenderer = coreRenderer, ...props } = this.props;

    if (node.nodeType === NODE_TYPE_ELEMENT_NODE) {
      const element = node as Element;

      return nodeRenderer(
        element,
        RichText.getAttributes(element),
        this.renderChildNodes(element),
        props
      );
    }

    return nodeRenderer(node, {}, [], props);
  }

  /**
   * Render children of an element
   *
   * @param parentNode Element
   */
  protected renderChildNodes(parentNode: Element): JSX.Element[] {
    const childNodes = parentNode.childNodes ? parentNode.childNodes : [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    return Array.prototype.slice.call(childNodes).map((node: Node) => this.renderNode(node));
  }

  public render(): JSX.Element {
    // eslint-disable-next-line
    const children = RichText.parse(this.props.children || '').map((node: Node) =>
      this.renderNode(node)
    );

    return <>{children}</>;
  }
}
