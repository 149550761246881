import { useState, useEffect } from 'react';
import { getUserId } from '../../utils/idk';
import type { IdkConfig } from '../use-env-config';
import {
  LAMBDA_CUSTOMER_PROFILE_URL_LIVE,
  LAMBDA_CUSTOMER_PROFILE_URL_TEST,
} from '../../utils/globals';

export interface Profile {
  readonly profilePicture?: {
    id: string;
    src?: string;
  };
}
async function getLambdaEndpoint(idk: any): Promise<string | undefined> {
  // eslint-disable-next-line no-useless-catch
  try {
    const isSandbox = idk.consentService?.baseUrl.includes('sandbox');

    return isSandbox
      ? LAMBDA_CUSTOMER_PROFILE_URL_TEST // endpoint to fetch profile picture and test on tui and pre-live environment
      : LAMBDA_CUSTOMER_PROFILE_URL_LIVE; // endpoint to fetch profile picture on production
  } catch (error) {
    throw error;
  }
}
export function useProfilePictureLamda(accessToken: string, idk?: IdkConfig): Profile {
  const [profilePicture, setProfilePicture] = useState<Profile['profilePicture'] | undefined>(
    undefined
  );

  useEffect(() => {
    async function fetchProfilePicture() {
      // eslint-disable-next-line no-useless-catch
      try {
        const url = await getLambdaEndpoint(idk);

        if (idk && url && accessToken) {
          const profilePicUrl = `${url}/profile-picture`;
          const userId = getUserId(accessToken);

          const response = await fetch(profilePicUrl, {
            headers: {
              Authorization: `${accessToken}`,
              userid: userId,
            },
          });

          if (!response.ok) {
            throw new Error(response.statusText);
          }

          if (response.status === 404) {
            setProfilePicture(undefined);
          } else {
            const data = await response.json();
            if (data) {
              setProfilePicture({ id: '', src: `data:image/png;base64,${data?.pictureCode}` });
            }
          }
        }
      } catch (error) {
        throw error;
      }
    }

    if (idk) {
      fetchProfilePicture();
    }
  }, [accessToken, idk]);

  return { profilePicture };
}
