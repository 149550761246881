import React from 'react';
import { FeatureAppLoader } from '@feature-hub/react';
import { Loader } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { timeL, timeS } from '../AudiHeader/AudiHeaderStyleUtils';
import AppLoaderErrorBoundary from '../AppLoaderErrorBoundary/AppLoaderErrorBoundary';
import { AudiHeaderSearchProps } from '../../interfaces/header-components.interfaces';
import { HeaderSearchStyledProps } from '../../interfaces/header-styled-components.interfaces';
import { createResetActiveItemAction } from '../../actions/header-actions';

const HeaderSearchControls = styled.div<HeaderSearchStyledProps>`
  position: absolute;
  top: 0;
  width: 100%;

  & > legend {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }

  & > button {
    background-color: transparent;
    border: none;
    color: var(--one-header-color-black);
    cursor: pointer;
    display: flex;
    left: var(--one-header-side-spacing);
    opacity: ${({ isSearchOpen }): string => (isSearchOpen ? '1' : '0')};
    padding: 0;
    position: absolute;
    top: var(--one-header-space-l);
    transition: opacity ${timeS};

    & > svg {
      height: 24px;
      transform: scaleX(-1);
      width: 24px;
    }
  }
`;

const HeaderSearchFeatureApp = styled.div<HeaderSearchStyledProps>`
  background-color: var(--one-header-color-white);
  margin: 0;
  opacity: ${({ isSearchOpen }): string => (isSearchOpen ? '1' : '0')};
  position: relative;
  transition: opacity ${timeS};
  width: 100%;
`;

const HeaderSearch = styled.div<HeaderSearchStyledProps>`
  background-color: var(--one-header-color-white);
  display: block;
  height: 71px;
  left: 0;
  opacity: ${({ isSearchOpen }): string => (isSearchOpen ? '1' : '0')};
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity ${({ theme }): string => theme.easing} ${timeL};
  visibility: ${({ isSearchOpen }): string => (isSearchOpen ? 'visible' : 'hidden')};
  width: 100%;
  z-index: 5;
`;

const LoaderContainer = styled.div`
  margin: 10px auto;
  text-align: center;
`;

const AudiHeaderSearch: React.FC<AudiHeaderSearchProps> = (props) => {
  const {
    isSearchOpen,
    search,
    headerDispatch,
    searchInputFeatureAppUrl,
    searchResultsFeatureAppUrl,
    headerStateService,
    requestLoadSearchApp,
  } = props;

  const searchInputFeatureAppRef = React.useRef(null);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void = (event) => {
    event.preventDefault();

    headerDispatch(createResetActiveItemAction(headerStateService));
  };

  // if the feature app delays in loading we trigger displaying the search input field once again
  React.useEffect(() => {
    if (
      isSearchOpen &&
      searchInputFeatureAppRef &&
      searchInputFeatureAppRef.current &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      searchInputFeatureAppRef.current.hasChildNodes()
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const childNodes = searchInputFeatureAppRef.current.childNodes[0];
      if (childNodes && !childNodes.hasChildNodes()) {
        let timesRun = 0;

        const intervalCheckFeatureAppLoaded = setInterval(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const childNodesUpdated = searchInputFeatureAppRef.current.childNodes[0];
          if (childNodesUpdated && childNodesUpdated.hasChildNodes()) {
            headerStateService.setShowSearch(true, true);
            clearInterval(intervalCheckFeatureAppLoaded);
            setShowLoader(false);
            return;
          }

          if (timesRun === 2) {
            setShowLoader(true);
          }

          // eslint-disable-next-line no-plusplus
          if (++timesRun > 100) {
            setShowLoader(false);
            clearInterval(intervalCheckFeatureAppLoaded);
          }
        }, 500);
      }
    }
  }, [isSearchOpen, headerStateService]);

  const searchContentJsx = requestLoadSearchApp ? (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AppLoaderErrorBoundary>
        <FeatureAppLoader featureAppId="header-search-input" src={searchInputFeatureAppUrl} />
      </AppLoaderErrorBoundary>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AppLoaderErrorBoundary>
        <FeatureAppLoader featureAppId="header-search-results" src={searchResultsFeatureAppUrl} />
      </AppLoaderErrorBoundary>
    </>
  ) : null;

  const backAriaLabel = `${useI18n({
    defaultMessage: 'Back',
    id: 'nemo.back',
  })}`;

  return (
    <>
      <HeaderSearch isSearchOpen={isSearchOpen}>
        {showLoader && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        <HeaderSearchFeatureApp isSearchOpen={isSearchOpen} ref={searchInputFeatureAppRef}>
          {searchContentJsx}
        </HeaderSearchFeatureApp>
        <HeaderSearchControls isSearchOpen={isSearchOpen}>
          <legend>{search?.Legend}</legend>
          <button aria-label={backAriaLabel} onClick={handleClick} type="button">
            <svg height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 16.9L15.5 11.5 10 6.1" fill="none" stroke="currentColor" />
            </svg>
          </button>
        </HeaderSearchControls>
      </HeaderSearch>
    </>
  );
};
export default AudiHeaderSearch;
