import React from 'react';
import {
  responsiveStyles,
  Text,
  Popover,
  Button,
  PopoverTrigger,
  Avatar,
  PopoverBody,
  Layout,
} from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { FeatureAppLoader } from '@feature-hub/react';
import RichText from '../RichText';
import AppLoaderErrorBoundary from '../AppLoaderErrorBoundary/AppLoaderErrorBoundary';
import { AudiUserMenuProps } from '../../interfaces/header-components.interfaces';
import { HeaderStyledLoginProps } from '../../interfaces/header-styled-components.interfaces';
import { createBreakpointStyles } from '../../services';
// import { getUserId } from '../../utils/idk';
import { coreRenderer } from '../RichText/core-renderer';
import { userMenuContent } from '../../i18n/usermenuContent';
import { useProfilePictureLamda } from '../../hooks/use-profile-picture';
import { useAuth } from '../../hooks/use-auth';
import { useUserInfo } from '../../hooks/use-user-info';
import { useEnvConfig } from '../../hooks/use-env-config';

const headerStyledLoginStyles = (
  hasDealer: boolean
): Array<{
  maxNavItems: number;
  style: unknown;
}> => [
  {
    maxNavItems: 0,
    style: null,
  },
  {
    maxNavItems: 4,
    style: {
      display: {
        l: hasDealer ? 'none' : 'block',
      },
    },
  },
  {
    maxNavItems: 5,
    style: {
      display: {
        xl: hasDealer ? 'none' : 'block',
      },
    },
  },
  {
    maxNavItems: 8,
    style: {
      display: {
        xxl: hasDealer ? 'none' : 'block',
      },
    },
  },
];

const HeaderStyledLogin = styled.div<HeaderStyledLoginProps>`
  display: ${({ isOpen }): string => (isOpen ? 'none' : 'block')};
  ${({ headerNavigationItemsAmount, theme, hasDealer }): string =>
    responsiveStyles(
      createBreakpointStyles(headerNavigationItemsAmount, headerStyledLoginStyles(hasDealer)),
      theme.breakpoints
    )}
  margin: var(--one-header-space-s) 0;
`;

const StyledPopoverContainer = styled(Popover)`
  width: 351px;
  top: 52px !important;
  :focus {
    outline: 0 !important;
  }
`;

const StyledAvatar = styled(Avatar)`
  & img {
    position: inherit;
  }
`;

const StyledLayout = styled(Layout)`
  overflow: hidden;
`;

const AudiUserMenu: React.FC<AudiUserMenuProps> = ({
  authService,
  envConfigService,
  headerNavigationItemsAmount,
  isOpen,
  onClickUserMenu,
  hasDealer,
  marketBaseUrl,
  redirectUrl,
  userAccountUrl,
  loginFeatureAppUrl,
  useUserMenu,
}) => {
  const benefitHeadlineLabel = useI18n(userMenuContent.benefitHeadline);
  const benefitSubHeadlineLabel = useI18n(userMenuContent.benefitSubHeadline);
  const benefitContent = useI18n(userMenuContent.benefitContent);
  const benefitLoginLabel = useI18n(userMenuContent.benefitLogin);
  const benefitMoreLabel = useI18n(userMenuContent.benefitMore);
  const benefitMarketUrl = useI18n(userMenuContent.benefitMarketUrl);
  const benefitMyAudi = useI18n(userMenuContent.benefitMyAudi);
  const benefitLogout = useI18n(userMenuContent.benefitLogout);
  const flyoutLabel = useI18n(userMenuContent.flyoutLabel);
  const { isAuthenticated, accessToken } = useAuth(authService);
  const { idk } = useEnvConfig(envConfigService);

  const { personalData } = useUserInfo(idk, accessToken);

  const profilePicture = useProfilePictureLamda(accessToken, idk);

  // Extract the first name and last name from personalData
  const firstName = personalData?.firstName;
  const lastName = personalData?.lastName;

  const initials = `${firstName?.charAt(0).toUpperCase() || ''}${
    lastName?.charAt(0).toUpperCase() || ''
  }`;

  const handleLoginClick = (event: React.MouseEvent) => {
    event.preventDefault();
    authService.login(
      undefined,
      typeof window !== 'undefined' ? window.document.location.href : undefined
    );
  };
  const handleRegistrationClick = (event: React.MouseEvent) => {
    event.preventDefault();
    authService.login(
      undefined,
      typeof window !== 'undefined' ? window.document.location.href : undefined
    );
  };

  const renderLoggedOutState = () => {
    return (
      <PopoverTrigger placement="bottom right" tip>
        {(triggerProps) => (
          <>
            <Button
              aria-label={flyoutLabel}
              variant="icon-tertiary"
              icon="user"
              size="small"
              type="button"
              onClick={onClickUserMenu}
              data-testid="FAUSERMENU_USERICON"
              {...triggerProps}
            />
            <StyledPopoverContainer aria-label="myAudi Customer Area">
              <PopoverBody>
                <Text
                  data-testid="FAUSERMENU_BENEFIT_HEADLINE"
                  as="h4"
                  variant="order4"
                  spaceStackEnd="s"
                  spaceStackStart="m"
                >
                  {benefitHeadlineLabel}
                </Text>
                <Text as="p" variant="copy1" spaceStackEnd="s" spaceStackStart="s">
                  {benefitSubHeadlineLabel}
                </Text>
                <RichText nodeRenderer={coreRenderer}>{benefitContent}</RichText>

                {benefitMarketUrl && (
                  <Button
                    href={marketBaseUrl}
                    aria-label="My Audi Page"
                    variant="text"
                    spaceStackEnd="xl"
                    data-testid="FAUSERMENU_DETAILSLINK"
                  >
                    <Text variant="copy1">{benefitMarketUrl}</Text>
                  </Button>
                )}
                <Button
                  aria-label="My Audi Login"
                  data-testid="FAUSERMENU_LOGINBUTTON"
                  variant="primary"
                  spaceStackEnd="s"
                  stretch
                  onClick={handleLoginClick}
                >
                  {benefitLoginLabel}
                </Button>
                <Button
                  onClick={handleRegistrationClick}
                  aria-label="Registration Page"
                  variant="secondary"
                  stretch
                >
                  {benefitMoreLabel}
                </Button>
              </PopoverBody>
            </StyledPopoverContainer>
          </>
        )}
      </PopoverTrigger>
    );
  };

  const renderLoggedInState = () => {
    const handleLogoutClick = (event: React.MouseEvent) => {
      event.preventDefault();
      authService.logout(redirectUrl);
    };

    return (
      <PopoverTrigger placement="bottom right" tip>
        {(triggerProps) => (
          <>
            <Button
              aria-label={flyoutLabel}
              variant="icon-tertiary"
              data-testid="FAUSERMENU_USERICON"
              icon={
                <>
                  {profilePicture.profilePicture ? (
                    <StyledAvatar
                      size="small"
                      label={!profilePicture.profilePicture?.src ? initials : ''}
                      src={profilePicture.profilePicture?.src}
                    />
                  ) : (
                    <Avatar size="small" label={initials} />
                  )}
                </>
              }
              size="small"
              className="tooltip"
              {...triggerProps}
            />
            <StyledPopoverContainer aria-label="myAudi Customer Area">
              <PopoverBody>
                <StyledLayout direction="column">
                  <Button
                    aria-label="myAudi Account"
                    data-testid="FAUSERMENU_LINKPORTALACCOUNT"
                    // eslint-disable-next-line
                    onClick={() => (window.location.href = userAccountUrl)}
                    variant="text"
                    icon="user"
                    type="button"
                  >
                    {benefitMyAudi}
                  </Button>
                  <Button
                    data-testid="FAUSERMENU_LINKLOGOUT"
                    spaceStackStart="l"
                    aria-label="Logout"
                    onClick={handleLogoutClick}
                    variant="text"
                    icon="logout"
                  >
                    {benefitLogout}
                  </Button>
                </StyledLayout>
              </PopoverBody>
            </StyledPopoverContainer>
          </>
        )}
      </PopoverTrigger>
    );
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AppLoaderErrorBoundary>
      <HeaderStyledLogin
        headerNavigationItemsAmount={headerNavigationItemsAmount}
        isOpen={isOpen}
        hasDealer={hasDealer}
        onClick={onClickUserMenu}
        data-testid="FAUSERMENU_TOGGLELINK"
      >
        {
          // eslint-disable-next-line
          useUserMenu ? (
            isAuthenticated ? (
              renderLoggedInState()
            ) : (
              renderLoggedOutState()
            )
          ) : (
            <FeatureAppLoader featureAppId="header-login" src={loginFeatureAppUrl} />
          )
        }
      </HeaderStyledLogin>
    </AppLoaderErrorBoundary>
  );
};

export default AudiUserMenu;
