import React from 'react';
import { IHeaderAction } from '../actions/header-actions';

export interface ActiveItem {
  anchor: HTMLAnchorElement | null;
  index: number;
  showSearch: boolean;
  showDealer: boolean;
  showLoginFlyout: boolean;
}

export interface HeaderState {
  activeItem: ActiveItem;
  requestLoadSearchApp?: boolean;
}

export const headerReducer: React.Reducer<HeaderState, IHeaderAction> = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_ITEM':
      return { ...state, ...action.payload, requestLoadSearchApp: true };
    case 'LOAD_SEARCH_FA':
      return { ...state, requestLoadSearchApp: true };
    default:
      return state;
  }
};
