import React, { useRef } from 'react';
import { IAuthServiceProviderV2, IAuthServiceV2 } from '@oneaudi/audi-auth-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { FeatureServiceDependencies } from '../FeatureHubAppDefinition';

interface State {
  readonly authServiceProvider: IAuthServiceProviderV2;
  readonly localeService: LocaleServiceV1;
}

interface ContextValue {
  readonly featureServices: FeatureServiceDependencies;
  readonly children: JSX.Element;
}

export const AuthContext = React.createContext<IAuthServiceV2>({} as IAuthServiceV2);
AuthContext.displayName = 'auth-context';

export const Context = React.createContext<State>({} as State);

export const ContextProvider = ({ featureServices, children }: ContextValue): JSX.Element => {
  const { 'vw:authService': authServiceProvider, 'locale-service': localeService } =
    featureServices;

  const inViewRef = useRef<HTMLDivElement>(null);

  const state: State = {
    authServiceProvider,
    localeService,
  };

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};
