import { OpeningHours } from './dealer-details';

export type WeekDay =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';
export const getWeekDay = (): WeekDay => {
  const curr = new Date();
  // TODO oADD internationalize Weekday names
  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  return <WeekDay>weekdays[curr.getDay() - 1];
};

export const timeFormatted = (time?: string): string | undefined => {
  const newTimeFormat = time && time.length > 5 ? time?.substring(0, 5) : time;
  return newTimeFormat?.replace(/^0/, '');
};

const getCurrentTime = () => {
  const date = new Date();
  const minutes = date.getMinutes().toString();
  return `${date.getHours()}:${minutes.length === 1 ? `0${minutes}` : minutes}`;
};

export const isServiceOpen = (
  timeOpen?: string,
  timeClosed?: string,
  currentTime?: string
): boolean => {
  let now = currentTime || getCurrentTime();
  now = now.length === 4 ? `0${now}` : now;
  let open = timeFormatted(timeOpen);
  open = open?.length === 4 ? `0${open}` : open;
  let closed = timeFormatted(timeClosed);
  closed = closed?.length === 4 ? `0${closed}` : closed;
  return open && closed ? (now >= open && now < closed) || false : false;
};

export const isBeforeOpening = (timeOpen?: string, currentTime?: string): boolean => {
  let now = currentTime || getCurrentTime();
  now = now.length === 4 ? `0${now}` : now;
  let open = timeFormatted(timeOpen);
  open = open?.length === 4 ? `0${open}` : open;
  return open ? now <= open || false : false;
};

export const getNextOpenDay = (week: OpeningHours[], today: OpeningHours) => {
  const restOfTheWeek = week.slice(week.indexOf(today) + 1, week.length);
  const reorderedWeek = restOfTheWeek.concat(week);
  return reorderedWeek.find((weekday) => weekday.timeRanges.length > 0);
};
