export const KEY_DEALER_DATA = 'selected-dealer-header-data';
export const KEY_DEALER_ID = 'selected-dealer';
export const customEaseOut = 'cubic-bezier(0.21, 0.55, 0.43, 1)';
export const MAX_LENGTH_DEALER_NAME = 30;
export const DEALER_ID_INTERVAL_TIMEOUT = 800;
export const DEALER_ID_INTERVAL_MAX = 5;
export const LAMBDA_CUSTOMER_PROFILE_URL_TEST =
  'https://fa-nemo-header.api.dev.arcade.apps.one.audi/api';
export const LAMBDA_CUSTOMER_PROFILE_URL_LIVE =
  'https://fa-nemo-header.api.prod.arcade.apps.one.audi/api';
